import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import Image from "../../components/Image";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import styles from './styles.module.scss'

const BlogPost = ({
    data,
    pageContext: {
      breadcrumb: { crumbs },
    },
}) => {
  const {markdownRemark: page} = data;

  return (
      <Layout>
        <SEO title={page.frontmatter.title} />
        
        <CustomBreadcrumbs crumbs={crumbs} crumbLabel={page.frontmatter.title} />
        
        <h1>{page.frontmatter.title}</h1>
        
        <Image imgName={page.frontmatter.featuredImage} />
        
        <p className={styles.date}>Published on: {page.frontmatter.date}</p>
        
        <div className="page-body" dangerouslySetInnerHTML={{__html: page.html}}/>
      </Layout>
  )
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    careerPage: PropTypes.object
  })
};

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        featuredImage
      }
      fields {
          slug
      }
    }
  }
`;
